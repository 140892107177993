import React from 'react';
import Layout from '../../components/Layout';
import {withIntl} from '../../i18n';
import PricingOld from '../../templates/PricingOld/Page';

class Page extends React.PureComponent {
  render() {
    return (
      <Layout page={'pricing'}>
        <PricingOld type={'private-cloud'}/>
      </Layout>
    );
  }
}

export default withIntl(Page);
